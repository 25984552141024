import React, { useEffect, useState, useMemo } from "react"

/* @MUI COMPONENT */
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardHeader from '@mui/material/CardHeader'
import CardContent from "@mui/material/CardContent"
import CardActions from '@mui/material/CardActions'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Skeleton from '@mui/material/Skeleton'
import Paper from "@mui/material/Paper"

import imageCompression from 'browser-image-compression';


/* @CUSTOM COMPONENT */
import axios from "../../helpers/axios"
import endpoints from "../../helpers/endpoints"
import BasicCircularProgress from "../../components/Utils/BasicCircularProgress"
import DoubtReview from "./DoubtReview"

import { useSnackbar } from "notistack"
import useToken from "../../helpers/custom-hooks/useToken"
import { Grid } from "@mui/material"

const Input = styled('input')({
    display: 'none',
})

export default function DoubtSolveDialog(props) {
    const [showReview, setShowReview] = useState(false)
    const [doubt, setDoubt] = useState('')
    const [doubtImage, setDoubtImage] = useState(new Blob())
    const [answerFile, setAnswerFile] = useState('')
    const [comment, setComment] = useState('')
    const [doubtLoading, setDoubtLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)

    const { token } = useToken()
    const { enqueueSnackbar } = useSnackbar()

    const config = {
        headers: {
            "x-auth-token": token,
        }
    }

    const fileUploadconfig = {
        headers: {
            "x-auth-token": token,
            "Content-Type": "multipart/form-data"
        }
    }

    const handleClose = () => {
        props.handleClose()
    }

    async function handleImageUpload(imageFile) {
        const options = {
            maxSizeMB: 5,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);
            return compressedFile
        } catch (error) {
            enqueueSnackbar("Image compression failed", { variant: 'error' })
        }
        return imageFile
    }

    const handleSubmit = async () => {
        if (!answerFile) {
            updateDoubt('')
            return
        }

        let formData = new FormData()
        formData.append("doubtImage", answerFile)
        try {
            setSubmitLoading(true)
            const response = await axios.post(endpoints.uploadDoubtAnswerImage, formData, fileUploadconfig)
            setSubmitLoading(false)
            updateDoubt(response.data)
        } catch (error) {
            enqueueSnackbar("Doubt image upload failed: " + error.response, { variant: 'error' })
        }
    }

    const onUploadAnswer = async ({ target }) => {
        if (target.files[0].size / 1024 / 1024 > 5) {
            return enqueueSnackbar("File size is not allowed to be greater than 5MB.", { variant: 'error' })
        }
        if (target.files[0]) {
            const compressedImage = await handleImageUpload(target.files[0])
            setAnswerFile(compressedImage)
        }
    }

    async function fetchDoubt(doubtId) {
        setDoubtLoading(true)
        axios
            .get(endpoints.getDoubtById + doubtId, config)
            .then((response) => {
                setDoubt(response.data)
                fetchDoubtImage(response.data.questionImage)
            })
            .catch((error) => {
                enqueueSnackbar("Doubt data fetch failed: " + error.response.data, { variant: 'error' })
            })
    }

    async function fetchDoubtImage(imageLink) {
        axios
            .get(`${process.env.REACT_APP_API_URL}/bucket/` + imageLink, { responseType: 'blob' })
            .then((response) => {
                setDoubtLoading(false)
                if (response.data.statusCode) {
                    enqueueSnackbar(response.data.message, { variant: 'error' })
                    return
                }
                setDoubtImage(response.data)
            })
            .catch((error) => {
                enqueueSnackbar("Doubt data fetch failed: " + error.response, { variant: 'error' })
            })
    }

    async function updateDoubt(image) {
        if (image === '' && comment === '') {
            enqueueSnackbar("Image or comment required", { variant: 'error' })
            return
        }
        var updatePayload = {}
        if (image !== '') {
            updatePayload = { ...updatePayload, answerImage: image }
        }

        if (comment !== '') {
            updatePayload = { ...updatePayload, comment: comment }
        }

        setSubmitLoading(true)

        axios
            .put(endpoints.updateDoubtById + props.doubtId, updatePayload, config)
            .then((response) => {
                setSubmitLoading(false)
                enqueueSnackbar(response.data, { variant: 'success' })
                handleClose()
                props.handleSuccess()
            })
            .catch((error) => {
                enqueueSnackbar("Doubt solve failed: " + error.response.data, { variant: 'error' })
            })
    }

    useEffect(() => {
        if (!props.open) return
        fetchDoubt(props.doubtId)
    }, [props.open])

    const appBar = useMemo(() =>
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Doubt Solve
                </Typography>
                <LoadingButton
                    color="secondary"
                    onClick={() => setShowReview(true)}
                    loading={doubtLoading || submitLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Save
                </LoadingButton>
            </Toolbar>
        </AppBar>, []
    )

    const doubtImageCard = useMemo(() =>
        <Card sx={{ maxWidth: 400 }}>
            <CardHeader title="Question Image" />
            <CardMedia
                component="img"
                image={URL.createObjectURL(doubtImage)}
            />
        </Card>, [doubtImage]
    )

    const answerUploadCard = useMemo(() =>
        <Card sx={{ maxWidth: 400 }}>
            {
                answerFile ?
                    <CardMedia
                        component="img"
                        image={URL.createObjectURL(answerFile)}
                    /> : <Skeleton variant="rectangular" width={400} height={236} />
            }
            <CardActions disableSpacing>
                <label htmlFor="doubt-answer">
                    <Input onChange={onUploadAnswer} accept="image/*" id="doubt-answer" type="file" />
                    <Button component="span" size="small">Upload Answer Image</Button>
                </label>
            </CardActions>
        </Card>, [answerFile]
    )

    const commentsCard = useMemo(() =>
        <Card sx={{ maxWidth: 400 }}>
            <CardHeader title="Comments" />
            <CardContent>
                <List>
                    {doubt ?
                        doubt.comments.map((item, index) => {
                            return (
                                <ListItem key={item.user + index}>
                                    <ListItemText>{item.comment}</ListItemText>
                                </ListItem>
                            )
                        }) : null
                    }
                </List>
            </CardContent>
        </Card>, [doubt]
    )

    const addCommentField = useMemo(() =>
        <TextField
            label="Add a comment"
            variant="standard"
            sx={{minWidth: 400, mt: 2 }}
            multiline
            maxRows={20}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
        />, [comment]
    )
    const doubtImageAndComment = useMemo(() =>
        <Paper sx={{ p: { md: 4, xs: 1 } }} variant="outlined" square spacing={4}>
            {doubtImageCard}
            {commentsCard}
        </Paper>
    )
    const uploadImageAndComment = useMemo(() =>
        <Paper sx={{ p: { md: 4, xs: 1 } }} variant="outlined" square spacing={4}>
            {answerUploadCard}
            {addCommentField}
        </Paper>
    )
    return (
        <Dialog fullScreen disableEnforceFocus open={Boolean(props.open)} onClose={handleClose}>
            {appBar}
            {doubtLoading ? <BasicCircularProgress /> :
                <Box sx={{ m: 2 }}>
                    <Grid
                        container
                        direction="row"
                        sx={{ justifyContent: { md: 'space-between', xs: 'center' } }}
                        alignItems="center"
                        spacing={4}
                    >
                        <Grid item md={6} xs={12}>
                            {doubtImageAndComment}
                        </Grid>
                        <Grid item md={6} xs={12}>
                            {uploadImageAndComment}
                        </Grid>
                    </Grid>
                </Box>}
            {showReview &&
                <DoubtReview
                    open={showReview}
                    answerFile={answerFile}
                    comment={comment}
                    handleCloseDialog={() => setShowReview(false)}
                    handleSubmit={handleSubmit}
                />}
        </Dialog>
    )
}